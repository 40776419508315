import {PhoneNumberUtil, PhoneNumberFormat} from "google-libphonenumber";

const pathToErrorTranslate = 'reservationPage.reservationForm.formErrors';

export const reservationValidation = (formData, setErrorsListHandler, setFormErrors, formErrors, phoneToShow, currentCountry, translate) => {

    let errors = {...formErrors};

    if (formData) {
        const trimmedValue = formData.userName.trim();
        if (trimmedValue.length === 0) {
            errors.userName = `${translate(`${pathToErrorTranslate}.emptyName`)}`;
        } else if (trimmedValue.length < 2) {
            errors.userName = `${translate(`${pathToErrorTranslate}.invalidName`)}`;
        } else {
            delete errors.userName;
        }
    }

    if (formData) {
        const trimmedValue = formData.userSurname.trim();
        if (trimmedValue.length < 2) {
            errors.userSurname = `${translate(`${pathToErrorTranslate}.invalidSurname`)}`;
        } else {
            delete errors.userSurname;
        }
        if (formData.userSurname.length === 0) {
            delete errors.userSurname;
        }
    }

    if (formData) {
        const trimmedValue = formData.patronymic.trim();
        if (trimmedValue.length < 2) {
            errors.patronymic = `${translate(`${pathToErrorTranslate}.invalidPatronymic`)}`;
        } else {
            delete errors.patronymic;
        }
        if (formData.patronymic.length === 0) {
            delete errors.patronymic;
        }
    }

    if (formData) {
        const phoneUtil = PhoneNumberUtil.getInstance();

        try {
            const parsedNumber = phoneUtil.parseAndKeepRawInput(phoneToShow.toString(), currentCountry.toUpperCase());

            if (phoneUtil.isValidNumber(parsedNumber)) {
                formData.userPhone = phoneUtil.format(parsedNumber, PhoneNumberFormat.E164);
                delete errors.userPhone;
            } else {
                errors.userPhone = `${translate(`${pathToErrorTranslate}.invalidPhone`)}`;
            }
        } catch (error) {
            errors.userPhone = `${translate(`${pathToErrorTranslate}.emptyPhone`)}`;
        }
    }

    if (formData) {
        const trimmedValue = formData.userEmail.trim();
        if (trimmedValue.length === 0) {
            errors.userEmail = `${translate(`${pathToErrorTranslate}.emptyEmail`)}`;
        } else {
            const emailPattern = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;
            if (!emailPattern.test(trimmedValue)) {
                errors.userEmail = `${translate(`${pathToErrorTranslate}.invalidEmailRef`)}`;
            } else {
                delete errors.userEmail;
            }
        }
    }

    if (formData) {
        const trimmedValue = formData.userComment.trim();
        if (trimmedValue.length < 2) {
            errors.userComment = `${translate(`${pathToErrorTranslate}.invalidComment`)}`;
        } else {
            delete errors.userComment;
        }
        if (formData.userComment.length === 0) {
            delete errors.userComment;
        }
    }

    setFormErrors(errors);
    setErrorsListHandler(errors)

    return errors
 }