import ContentWrapper from "../../UI/ContentWrapper/ContentWrapper";
import {useNavigate} from "react-router-dom";
import {useParams} from "react-router-dom";
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import './AboutBranch.scss'
import defaultLogo from './branchLogo.svg'
import OwlCarousel from "react-owl-carousel";
import {useQuery} from "@apollo/client";
import {COMPANY_BRANCH} from "../../apollo/Queries";
import {apolloClientPos} from "../../apollo/ApolloClients";
import {useEffect, useRef, useState} from "react";
import Spinner from "../../components/Spinner/Spinner";
import PageNotFound from "../../components/ErrorComponents/PageNotFound/PageNotFound";
import {useTranslation} from "react-i18next";
import {setBranch} from "../../store/branchReducer";
import {useDispatch, useSelector} from "react-redux";
import Fancybox from "../../components/FancyboxWrapperComponent/FancyboxWrapperComponent";
import {setBookingValidate} from "../../store/bookingValidateReducer";
import {NavigationComponent} from "../../components/NavigationComponent/NavigationComponent";
import {AddEnvToImgRef} from "../../utils/addEnvToImgRef";

const AboutBranch = () => {
    const {t: translate} = useTranslation();
    const {branchId, companyId, from} = useParams();
    const carouselRef = useRef(null);
    const companyLogo = useSelector(state => state.companyReducer.companyImage);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const isBookingValid = useSelector(state => state.bookingValidateReducer.validate);
    const [imagesForSlider, setImagesForSlider] = useState([]);

    useEffect(() => {
        if (!isBookingValid) {
            dispatch(setBookingValidate({validate: true}))
        }
    }, [dispatch, isBookingValid])

    const handleNext = () => {
        carouselRef.current.next();
    };

    const handlePrev = () => {
        carouselRef.current.prev();
    };

    const sliderOptions = {
        items: 1,
        loop: false,
        margin: 10,
        nav: false,
        dots: true
    };


    const [loading, setLoading] = useState(true);

    const {data: companyBranch} = useQuery(COMPANY_BRANCH(branchId), {
        client: apolloClientPos,
        onCompleted: (data) => {
            const mergedArray = data.branch.branchImages.concat(data.branch.serviceImages);
            setImagesForSlider(mergedArray)
            setLoading(false);
        }, onError: () => {
            setLoading(false);
        }
    });

    const pickBranchHandler = () => {
        dispatch(setBranch({
            branchName: companyBranch.branch.name,
            branchAddress: companyBranch.branch.address,
            branchCity: companyBranch.branch.city.name
        }));
        navigate(`/company/${companyId}/branch-menu-list/${branchId}`);
    }

    const logoToShow = (branchLogo, companyLogo, defaultLogo) => {
        return branchLogo ? `${process.env.REACT_APP_POS_URL}/${branchLogo}` : companyLogo ? companyLogo : defaultLogo
    }


    return (
        <>
            <div className={"bg-white"}>
                    {from ?
                        <NavigationComponent
                            navLink={`/company/${companyId}/branch-menu-list/${branchId}`}
                            linkText={translate('backToMenu')}
                        />
                        :
                        <NavigationComponent
                            navLink={`/company/${companyId}`}
                            linkText={translate('aboutBranchPage.toBranchList')}
                        />
                    }
            </div>
            <div className="current-branch ">
                {(companyBranch && companyBranch.branch) ?
                    <>
                        <ContentWrapper>
                            <div
                                className={"d-flex flex-column flex-lg-row justify-content-between align-items-center"}>
                                <h5 className="mb-3 mb-lg-0 branch-name">
                                    {companyBranch.branch.name ? companyBranch.branch.name : 'Невідома назва'},
                                    м.{companyBranch.branch.city.name ? companyBranch.branch.city.name : 'Місто не вказано'}</h5>
                                <button onClick={pickBranchHandler}
                                        className="btn btn-primary col-12 col-lg-4 fw-bold fs-7 rounded">
                                    {translate('aboutBranchPage.choiceBranch')}
                                </button>
                            </div>
                        </ContentWrapper>
                        <ContentWrapper>
                            <div
                                className="logo_branch-name d-flex flex-column justify-content-center align-items-center">
                                <div className="logo_wrapper mb-2">
                                    <img src={logoToShow(companyBranch.branch.image, companyLogo, defaultLogo)}
                                         alt="logo"/>
                                </div>
                                <h4 className="mb-0 main_text_color mb-4 text-center">{companyBranch.branch.name}</h4>
                                {companyBranch.branch.description && (
                                    <div className="branch-description w-100">
                                        <h5 className="mb-2 main_text_color branch-name">
                                            {translate('aboutBranchPage.aboutBranch')}
                                        </h5>
                                        {companyBranch.branch.description && (
                                            <p className="mb-0 text-secondary fs-18">{companyBranch.branch.description}</p>)}
                                    </div>
                                )}
                            </div>
                        </ContentWrapper>
                        <ContentWrapper>
                            <h5 className="mb-2 main_text_color branch-name">
                                {translate('aboutBranchPage.workSchedule')}
                            </h5>
                            <p className="mb-0 main_text_color fs-18">
                                {companyBranch.branch.workSchedule ?
                                    companyBranch.branch.workSchedule :
                                    `${translate('aboutBranchPage.workScheduleNotFound')}`}</p>
                        </ContentWrapper>
                        <ContentWrapper>
                            <h5 className="mb-2 main_text_color branch-name">
                                {translate('aboutBranchPage.contacts')}
                            </h5>
                            <p className="mb-0 fs-18">
                                {(companyBranch.branch.phones && companyBranch.branch.phones.length !== 0) ?
                                    <>
                                        {companyBranch.branch.phones.map((phoneObj, index) => {
                                            return <span key={index}>
                                                <a href={`tel:${phoneObj.phone}`}
                                                   className="main_text_color text-decoration-none">
                                                    {phoneObj.phone}
                                                </a>
                                                <span
                                                    className="text-secondary"> - {translate('aboutBranchPage.admin')}</span>
                                            </span>
                                        })}
                                    </>
                                    :
                                    `${translate('aboutBranchPage.contactsNotFound')}`}
                            </p>
                        </ContentWrapper>
                        <div className="mb-5">
                            <ContentWrapper>
                                <h5 className="mb-2 main_text_color branch-name">
                                    {translate('aboutBranchPage.address')}
                                </h5>
                                <p className="mb-0 fs-18 main_text_color">{companyBranch.branch.address ?
                                    companyBranch.branch.address :
                                    `${translate('aboutBranchPage.addressNotFound')}`}</p>
                            </ContentWrapper>
                        </div>
                        {imagesForSlider.length !== 0 ?
                            <ContentWrapper>
                                <div className={'position-relative slider-wrapper'}>
                                    <Fancybox options={{
                                        Carousel: {
                                            infinite: false,
                                        },
                                    }}>
                                        <OwlCarousel className='owl-theme' ref={carouselRef} {...sliderOptions}>
                                            {
                                                AddEnvToImgRef(imagesForSlider).map((sliderImage, index) => (
                                                    <div className="image_wrapper" key={index}>
                                                        <a href={sliderImage} data-fancybox="gallery">
                                                            <img src={sliderImage} alt=""
                                                                 className="object-fit-cover w-100"/>
                                                        </a>
                                                    </div>
                                                ))
                                            }
                                        </OwlCarousel>
                                    </Fancybox>
                                    <button className={'btn nav-btn-prev icon_to_block d-none d-md-block'}
                                            onClick={handlePrev}>
                                        <i className="bi bi-chevron-left"></i>
                                    </button>
                                    <button className={'btn nav-btn-next icon_to_block d-none d-md-block'}
                                            onClick={handleNext}>
                                        <i className="bi bi-chevron-right"></i>
                                    </button>
                                </div>
                            </ContentWrapper>
                            : ''}
                        <div className={'p-4 mb-4 col-lg-8 mx-auto container d-lg-none d-block'}>
                            <button onClick={pickBranchHandler}
                                    className="btn btn-primary mx-auto rounded col-12">
                                {translate('aboutBranchPage.choiceBranch')}
                            </button>
                        </div>
                    </>
                    : loading ? <Spinner/> : <PageNotFound/>
                }
            </div>
        </>
    )
}

export default AboutBranch
