import React, { useRef, useEffect, useState } from "react";
import FreeTimeComponent from "../../../../components/FreeTimeComponent/FreeTimeComponent";
import { Collapse } from "bootstrap";

const MasterFreeTime = ({
                            masterDate,
                            closestBookingDateTimes,
                            employeeId,
                            closestDate,
                        }) => {
    const [isOpen, setIsOpen] = useState(false);
    const collapseRef = useRef(null);

    useEffect(() => {
        if (collapseRef.current) {
            const bsCollapse = new Collapse(collapseRef.current, { toggle: false });
            if (isOpen) {
                bsCollapse.show();
            } else {
                bsCollapse.hide();
            }
        }
    }, [isOpen]);

    const toggleAccordion = () => {
        setIsOpen((prevState) => !prevState);
    };

    const uniqueId = `accordion-${employeeId}`;

    return (
        <div className="master-free-time">
            <div className="accordion" id={uniqueId}>
                <div className="accordion-item">
                    <h2 className="accordion-header" id={`heading-${uniqueId}`}>
                        <button
                            className={`accordion-button p-0 main_text_color ${
                                isOpen ? "" : "collapsed"
                            }`}
                            type="button"
                            onClick={toggleAccordion}
                            aria-expanded={isOpen}
                            aria-controls={`collapse-${uniqueId}`}
                        >
                            {masterDate}
                        </button>
                    </h2>
                    <div
                        id={`collapse-${uniqueId}`}
                        className="accordion-collapse collapse"
                        ref={collapseRef}
                        aria-labelledby={`heading-${uniqueId}`}
                    >
                        <div className="accordion-body px-0 pt-3 pb-0">
                            <FreeTimeComponent
                                employeeId={employeeId}
                                intervals={closestBookingDateTimes}
                                closestDate={closestDate}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default MasterFreeTime;
