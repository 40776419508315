import './MasterItem.scss'
import MainMasterInfo from "./MainMasterInfo/MainMasterInfo";
import MasterFreeTime from "./MasterFreeTime/MasterFreeTime";
import DateConvertToShow from "../../../utils/workingWithDateTime/DateConvertToShow";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import {Link, useParams} from "react-router-dom";
import PickMasterComponent from "../../../components/PickMasterComponent/PickMasterComponent";

const MasterItem = ({
                        masterDesc,
                        masterNearestEntry,
                        masterName,
                        masterPhoto,
                        closestBookingDateTimes,
                        employeeId,
                        avgRating,
                        reviewCount,
                        masterSurname,
                        aboutMaster,
                        employeeServiceRelation
                    }) => {

    const {t: translate, i18n} = useTranslation();
    const currentLanguage = i18n.language;
    const dateIsEntered = useSelector(state => state.dateReducer.dateIsEntered);
    const {companyId, branchId} = useParams();

    return (
        <div className="master-item position-relative">
            <MainMasterInfo
                name={masterName}
                position={masterDesc}
                photo={masterPhoto}
                employeeId={employeeId}
                textColor="text-secondary"
                rating={avgRating}
                reviewCount={reviewCount}
                surname={masterSurname}
                employeeServiceRelation={employeeServiceRelation}
            />
            {aboutMaster && <p className="text-secondary mt-3 fs-18">{aboutMaster}</p>}
            <div className="d-block d-md-none mb-4">
                <div className="d-flex flex-column">
                    <Link
                        to={`/company/${companyId}/branch-menu-list/${branchId}/masters/${employeeId}`}
                        className="btn btn-outline-primary fs-7 rounded mb-3"
                    >
                        {translate('masterListPage.aboutMaster')}
                    </Link>
                    <PickMasterComponent
                        masterName={masterName}
                        employeeId={employeeId}
                        masterPosition={masterDesc}
                        masterPhoto={masterPhoto}
                        masterSurname={masterSurname}
                        employeeServiceRelation={employeeServiceRelation}
                        fullSizeBtn="w-100"
                    />
                </div>
            </div>

            {!dateIsEntered && (
                <div className="mt-3">
                    <MasterFreeTime
                        employeeId={employeeId}
                        masterDate={`${translate('masterListPage.closesTime')} ` +
                            DateConvertToShow(new Date(masterNearestEntry), currentLanguage)}
                        closestBookingDateTimes={closestBookingDateTimes}
                        closestDate={masterNearestEntry}
                        uniqueId={`master-free-time-${employeeId}`}
                    />
                </div>
            )}
        </div>
    );
};

export default MasterItem;
