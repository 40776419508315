import 'bootstrap/js/src/collapse'
import FreeTimeComponent from "../FreeTimeComponent/FreeTimeComponent";
import {accordionOpenPosition} from "../../utils/accordionOpenPosition";
import {useTranslation} from "react-i18next";

const AccordionComponentEvening = ({
                                       nightIntervals,
                                       accordionPosition,
                                       currentDate,
                                       dayIsOpen,
                                       morningIsOpen,
                                       convertedDateByTimeZone,
                                       foolInfoMasterStyles,
                                       employeeId,
                                   }) => {
    const {t: translate} = useTranslation();
    let isOpen = false;
    const nightIntervalsIsNotEmpty = nightIntervals.length !== 0;
    if (nightIntervalsIsNotEmpty && !dayIsOpen && !morningIsOpen) {
        const lastTimeToReservation = nightIntervals[nightIntervals.length - 1]?.end;
        const lastTimeToReservationArray = lastTimeToReservation?.split(':')
        const currentHour = parseInt(lastTimeToReservationArray[0], 10);
        const currentMin = parseInt(lastTimeToReservationArray[1], 10);
        const currentSec = parseInt(lastTimeToReservationArray[2], 10);
        isOpen = accordionOpenPosition(currentHour, currentMin, currentSec, accordionPosition, convertedDateByTimeZone);
    }
    if (convertedDateByTimeZone < currentDate && nightIntervalsIsNotEmpty && !dayIsOpen && !morningIsOpen) {
        isOpen = true;
    }
    return (
        <div>
            <div className={`accordion-item ${foolInfoMasterStyles ? 'accordion-block-styles' : ''}`}>
                <h2 className="accordion-header" id="headingThree">
                    <button
                        className={`p-0 accordion-button fw-bold ${isOpen ? '' : 'collapsed'} ${foolInfoMasterStyles ? 'py-1 px-2 accordion-header-style' : ''}`}
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseThree" aria-expanded={isOpen} aria-controls="collapseThree">
                        {translate('dateAndTimePage.nightTime')}
                    </button>
                </h2>
                <div id="collapseThree" className={`accordion-collapse collapse ${isOpen ? 'show' : ''}`}
                     aria-labelledby="headingThree"
                     data-bs-parent="#accordion-times">
                    <div className={`accordion-body ${foolInfoMasterStyles ? 'px-4 pt-3 pb-4' : 'px-0 pt-3'}`}>
                        <FreeTimeComponent intervals={nightIntervals}
                                           closestDate={currentDate}
                                           employeeId={employeeId}/>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AccordionComponentEvening;