import {useState} from "react";
import {Link, useNavigate, useParams} from "react-router-dom";
import ReservationInfo from "../ReservationInfo/ReservationInfo";
import {useDispatch, useSelector} from "react-redux";
import {setUserInfo} from "../../../store/formReducer";
import {useMutation, useQuery} from "@apollo/client";
import {CREATE_BOOKING} from "../../../apollo/Mutations";
import {createBookingData} from "../../../utils/reservationUtils/createBooking";
import Spinner from "../../../components/Spinner/Spinner";
import PhoneInput from 'react-phone-input-2';
import './ReservationForm.scss'
import {COMPANY_BRANCH_INFO, EMPLOYER_LIST_FOR_DEFAULT_MASTER} from "../../../apollo/Queries";
import {apolloClientPos, apolloClientPosBranch} from "../../../apollo/ApolloClients";
import {convertDateTimeForRequest} from "../../../utils/workingWithDateTime/convertDateTimeForRequest";
import {Trans, useTranslation} from "react-i18next";
import selectOptionsControls from "../../../utils/selectOptionsControlls";
import {setBookingValidate} from "../../../store/bookingValidateReducer";
import {setBranch} from "../../../store/branchReducer";
import {reservationValidation} from "../../../utils/reservationUtils/reservationValidation";
import convertDateByTimezone from "../../../utils/workingWithDateTime/convertDateByTimezone";
import {POS_URL} from "../../../environment";

const ReservationForm = ({showForm, setShowForm, setErrorsListHandler, errorsListIsEmpty}) => {
    const {t: translate} = useTranslation();
    const pathToFormTranslate = 'reservationPage.reservationForm';
    const [isDisabled, setIsDisabled] = useState(true);
    const {companyId, branchId} = useParams();
    const formData = useSelector(state => state.formReducer);
    const currentLanguage = useSelector(state => state.companyReducer.language);
    const countryIsoCode = useSelector(state => state.companyReducer.countryIsoCode)
    const currentBranchInfo = useSelector(state => state.branchReducer);
    const currentDate = useSelector(state => state.dateReducer.currentDate)
    const currentService = useSelector(state => state.serviceReducer.serviceId);
    const currentEmployee = useSelector(state => state.employeeReducer.employeeId);
    const companyReCall = useSelector(state => state.companyReducer.confirmationCallRequired);
    const timeZone = useSelector(state => state.branchReducer.timeZone);
    const currentTimeByTimeZone = convertDateByTimezone(timeZone);
    const [loading, setLoading] = useState(true);
    const [formErrors, setFormErrors] = useState({});
    const [phoneToShow, setPhoneToShow] = useState('');
    const [currentCountry, setCurrentCountry] = useState('');


    useQuery(COMPANY_BRANCH_INFO(branchId), {
        client: apolloClientPos,
        onCompleted: (data) => {
            setLoading(false);
            if (!currentBranchInfo.branchName) {
                dispatch(setBranch({
                    branchName: data.branch.name,
                    branchAddress: data.branch.address,
                    branchCity: data.branch.city.name,
                    timeZone: data.branch.timeZone
                }));
            }
        }, onError: () => {
            setLoading(false);
        }
    });

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const {data: employerList} = useQuery(EMPLOYER_LIST_FOR_DEFAULT_MASTER,
        {
            client: apolloClientPosBranch,
            variables: {
                branchId: branchId,
                picketDate: convertDateTimeForRequest(currentDate),
                serviceId: currentService
            }, onCompleted: () => {
                setLoading(false)
            }
        });

    const [createBooking] = useMutation(CREATE_BOOKING, {
        onCompleted: (data) => {
            setLoading(false);
            navigate(`/company/${companyId}/branch-menu-list/${branchId}/reservation/confirmed/${data.createAppBooking.uuid}`);
        },
        onError: (errors) => {
            const validationMessage = 'validation'
            if (errors.message === validationMessage) {
                dispatch(setBookingValidate({validate: false}));
                window.location = `/company/${companyId}/branch-menu-list/${branchId}`;
            }
        },
    });

    const checkRightsCheckboxHandler = () => {
        setIsDisabled(!isDisabled);
    }

    const checkLanguage = (currentLanguage) => {
        const languageLowerCase = currentLanguage?.toLowerCase()
        if (languageLowerCase !== 'en') {
            return `/${languageLowerCase}`
        }
        return ''
    }

    const handlePhoneInputChange = (value, country) => {
        delete formErrors.userPhone;

        setPhoneToShow(value);
        setCurrentCountry(country.countryCode);
        setErrorsListHandler(formErrors);
        const userPhone = `+${value}`
        dispatch(setUserInfo({userPhone: userPhone}));
    }

    const handleInputChange = (event) => {

        const target = event.target;
        const value = target.type === 'checkbox' ? !target.checked : target.value;
        const name = target.name;
        dispatch(setUserInfo({[name]: value}));

        let errors = {...formErrors}


        for (const prop in errors) {
            if (prop === name) {
                target.classList.remove('is-invalid');
                const {[name]: _, ...newErrors} = errors;
                setFormErrors(newErrors);
                setErrorsListHandler(newErrors);
                return;
            }
        }

    }

    const handleSubmit = (event) => {
        event.preventDefault();
        reservationValidation(formData, setErrorsListHandler, setFormErrors, formErrors, phoneToShow, currentCountry, translate);

        let employeeToSet = currentEmployee;

        if (currentEmployee === '-1') {
            const defaultEmployeeList = employerList.employees.filter((employee) => {
                return employee.allowDefault ? employee : null
            });
            employeeToSet = defaultEmployeeList[0]?.id
        }

        const errors = reservationValidation(formData, setErrorsListHandler, setFormErrors, formErrors, phoneToShow, currentCountry, translate);

        if (Object.keys(errors).length === 0) {
            const input = createBookingData(formData, currentService, employeeToSet, branchId, currentDate, companyReCall);
            createBooking({variables: {branchId: parseInt(branchId), input: input}});
            setLoading(true);
        } else {
            setShowForm(true);
        }

    }

    return (
        <>
            {loading
                ?
                <Spinner/>
                :
                <form onSubmit={handleSubmit}>
                    {showForm ?
                        <div className={'form'}>
                            <div className={"row"}>
                                <div className={"col-lg-4 col-12"}>
                                    <div className="form-floating mb-3 position-relative">
                                        <input type="text"
                                               className={`form-control ${formErrors.userName ? 'is-invalid' : ''}`}
                                               onInput={handleInputChange} id="userName"
                                               value={formData.userName}
                                               name="userName" required
                                               placeholder={translate(`${pathToFormTranslate}.userName`)}
                                               maxLength={50}></input>
                                        <label className={"text-gray d-fles"} htmlFor="userName">
                                            {translate(`${pathToFormTranslate}.userName`)}
                                        </label>
                                        <span className="text-danger position-absolute top-0 mt-2 me-3 end-0">*</span>
                                        {formErrors.userName && (
                                            <div className="invalid-feedback ps-1 ms-2">{formErrors.userName}</div>
                                        )}
                                    </div>
                                </div>
                                <div className={"col-lg-4 col-12"}>
                                    <div className="form-floating mb-3">
                                        <input type="text"
                                               className={`form-control ${formErrors.userSurname ? 'is-invalid' : ''}`}
                                               onInput={handleInputChange}
                                               value={formData.userSurname}
                                               id="userSurname" name="userSurname"
                                               placeholder={translate(`${pathToFormTranslate}.userSurname`)}
                                               maxLength={50}></input>
                                        <label className={"text-gray"} htmlFor="userSurname">
                                            {translate(`${pathToFormTranslate}.userSurname`)}
                                        </label>
                                        {formErrors.userSurname && (
                                            <div className="invalid-feedback ps-1 ms-2">{formErrors.userSurname}</div>
                                        )}
                                    </div>
                                </div>
                                <div className={"col-lg-4 col-12"}>
                                    <div className="form-floating mb-3">
                                        <input type="text"
                                               className={`form-control ${formErrors.patronymic ? 'is-invalid' : ''}`}
                                               onInput={handleInputChange}
                                               value={formData.patronymic}
                                               id="patronymic"
                                               placeholder={translate(`${pathToFormTranslate}.userPatronymic`)}
                                               name={'patronymic'}
                                               maxLength={50}></input>
                                        <label className={"text-gray"} htmlFor="patronymic">
                                            {translate(`${pathToFormTranslate}.userPatronymic`)}
                                        </label>
                                        {formErrors.patronymic && (
                                            <div className="invalid-feedback ps-1 ms-2">{formErrors.patronymic}</div>
                                        )}
                                    </div>
                                </div>

                                <div className={"col-lg-6 col-12"}>
                                    <div className="input-wrapper position-relative d-flex flex-column mb-3">
                                        <label htmlFor="userPhone"
                                               className="req_star text-secondary position-absolute mt-2 me-3 end-0">
                                            <span className="text-danger">*</span>
                                        </label>
                                        <div>
                                            <PhoneInput
                                                inputProps={{
                                                    id: 'userPhone',
                                                    name: 'userPhone',
                                                    className: `form-control main_text_color ${formErrors.userPhone ? 'is-invalid' : ''}`,
                                                    placeholder: '___.__.__',
                                                }}
                                                country={countryIsoCode?.toLowerCase()}
                                                value={phoneToShow}
                                                onChange={handlePhoneInputChange}
                                            />
                                        </div>
                                        {formErrors.userPhone && (
                                            <div
                                                className="text-danger fs-7 ps-1 ms-2 mt-1">{formErrors.userPhone}
                                            </div>
                                        )}
                                    </div>
                                </div>
                                <div className={"col-lg-6 col-12 position-relative"}>
                                    <div className="form-floating mb-3">
                                        <input type="email"
                                               className={`form-control ${formErrors.userEmail ? 'is-invalid' : ''}`}
                                               name={'userEmail'}
                                               value={formData.userEmail}
                                               onInput={handleInputChange}
                                               required
                                               id="userEmail"
                                               placeholder={translate(`${pathToFormTranslate}.userEmail`)}
                                               maxLength={256}/>
                                        <label className={"text-gray"} htmlFor="userEmail">
                                            {translate(`${pathToFormTranslate}.userEmail`)}
                                        </label>
                                        <span className="text-danger position-absolute top-0 mt-2 me-3 end-0">*</span>
                                        {formErrors.userEmail && (
                                            <div className="invalid-feedback ps-1 ms-2">{formErrors.userEmail}</div>
                                        )}
                                    </div>
                                </div>
                            </div>
                            <div className="form-floating mb-3">
                                  <textarea
                                      className={`form-control booking-comment ${formErrors.userComment ? 'is-invalid' : ''}`}
                                      name={'userComment'}
                                      value={formData.userComment}
                                      onInput={handleInputChange}
                                      placeholder={translate(`${pathToFormTranslate}.userComment`)} rows="3"
                                      id="userComment"
                                      maxLength={200}></textarea>
                                {formErrors.userComment && (
                                    <div className="invalid-feedback ps-1 ms-2">{formErrors.userComment}</div>
                                )}
                                <label className={"text-gray"} htmlFor="userComment">
                                    {translate(`${pathToFormTranslate}.userComment`)}
                                </label>
                            </div>
                            {selectOptionsControls(currentDate, '30m', currentTimeByTimeZone) ?
                                <>
                                    <div className="form-floating input-wrapper mb-5">
                                        <select className="form-select" id="userReminder"
                                                onChange={handleInputChange}
                                                value={formData.notification}
                                                disabled={!formData.isNeedNotification}
                                                name={'notification'} aria-label="Floating label select example">
                                            {selectOptionsControls(currentDate, '30m', currentTimeByTimeZone) ?
                                                <option defaultValue value={'1'}>
                                                    {translate(`${pathToFormTranslate}.notifications.halfHour`)}
                                                </option>
                                                : null
                                            }
                                            {selectOptionsControls(currentDate, '1h', currentTimeByTimeZone) ?
                                                <option value={'2'}>
                                                    {translate(`${pathToFormTranslate}.notifications.oneHour`)}
                                                </option>
                                                : null
                                            }
                                            {selectOptionsControls(currentDate, '2h', currentTimeByTimeZone) ?
                                                <option value="3">
                                                    {translate(`${pathToFormTranslate}.notifications.twoHour`)}
                                                </option>
                                                : null
                                            }
                                            {selectOptionsControls(currentDate, '3h', currentTimeByTimeZone) ?
                                                <option value="4">
                                                    {translate(`${pathToFormTranslate}.notifications.threeHour`)}
                                                </option>
                                                : null
                                            }
                                            {selectOptionsControls(currentDate, '1d', currentTimeByTimeZone) ?
                                                <option value="5">
                                                    {translate(`${pathToFormTranslate}.notifications.oneDay`)}
                                                </option>
                                                : null
                                            }
                                            {selectOptionsControls(currentDate, '3d', currentTimeByTimeZone) ?
                                                <option value="6">
                                                    {translate(`${pathToFormTranslate}.notifications.threeDays`)}
                                                </option>
                                                : null
                                            }
                                            <option value="7">
                                                {translate(`${pathToFormTranslate}.notifications.dontNotification`)}
                                            </option>
                                        </select>
                                        <label className={"text-gray"} htmlFor="userReminder">
                                            {translate(`${pathToFormTranslate}.notification`)}
                                        </label>
                                    </div>
                                </>
                                :
                                null}
                        </div>
                        :
                        <ReservationInfo errorsListIsEmpty={errorsListIsEmpty}/>
                    }
                    <div className={"row"}>
                        {!companyReCall && (<div className="input-wrapper d-flex mb-3">
                            <input className="form-check-input me-2" type="checkbox"
                                   onChange={handleInputChange}
                                   checked={!formData.isNeedClientRecall}
                                   name={'isNeedClientRecall'} id="isNeedClientRecall"/>
                            <label className="form-check-label fs-7 main_text_color w-75" htmlFor="isNeedClientRecall">
                                {translate(`${pathToFormTranslate}.reCollOff`)}
                            </label>
                        </div>)}

                        <div className="input-wrapper mb-3 d-flex">
                            <input className="form-check-input me-2" type="checkbox" value="" id="flexCheckDefault"
                                   onChange={checkRightsCheckboxHandler}/>
                            <label className="form-check-label fs-7 main_text_color w-75" htmlFor="flexCheckDefault">
                                <Trans i18nKey={`${pathToFormTranslate}.rights`}
                                       components={{
                                           1: <a href={`${POS_URL}${checkLanguage(currentLanguage)}/terms-and-conditions`}>Угоду Користувача</a>,
                                           3: <a href={`${POS_URL}${checkLanguage(currentLanguage)}/privacy-policy`}>Політику Конфіденційності</a>
                                       }}
                                />
                            </label>
                        </div>
                        <div className={"col-lg-6 mb-3"}>
                            <Link
                                to={`/company/${companyId}/branch-menu-list/${branchId}`}
                                className={'btn mt-3 mt-md-0 mt-xl-0 btn-outline-primary d-flex justify-content-center align-items-center w-100 fs-7 rounded icon_to_block'}>
                                <i className="bi bi-chevron-left fs-6 me-2"></i>
                                {translate("backToMenu")}
                            </Link>
                        </div>
                        <div className="col-lg-6  ">
                            <button
                                disabled={isDisabled}
                                className="btn btn-primary w-100 fw-bold fs-7 rounded-1">
                                {translate(`${pathToFormTranslate}.reservation`)}
                            </button>
                        </div>

                    </div>
                </form>}
        </>
    )
}

export default ReservationForm