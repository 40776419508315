export const languages = [
    {
        langName: "English",
        langISO: "en"
    },
    {
        langName: "Українська",
        langISO: "uk"
    },
    {
        langName: "Čeština",
        langISO: "cs"
    },
    {
        langName: "Bahasa Indonesia",
        langISO: "id"
    },
    {
        langName: "ภาษาไทย",
        langISO: "th"
    },
    {
        langName: "Tiếng Việt",
        langISO: "vi"
    },
    {
        langName: "العربية",
        langISO: "ar"
    },
    {
        langName: "हिन्दी",
        langISO: "hi"
    }
];
