import clockImage from "../../img/clockLogo.svg";
import {useSelector} from "react-redux";
import {useTranslation} from "react-i18next";

const CurrentTime = () => {

    const {i18n} = useTranslation();
    const {t: translate} = useTranslation();
    const picketDate = useSelector(state => state.dateReducer.currentDate);
    const currentTime= new Date(picketDate).toLocaleTimeString([], { hour: '2-digit', minute:'2-digit' });
    const currentDate = new Date(picketDate).toLocaleDateString([], { day: '2-digit', month: '2-digit', year: 'numeric' });
    const locale = i18n.language.toLowerCase()
    const dayOfWeek = new Date(picketDate).toLocaleString(locale, {weekday: 'long'});

  return (
      <div className="timme-info d-flex align-items-center w-100">
          <div className="image-wrapper me-4 master-photo__wrapper rounded-circle">
              <img src={clockImage} className="d-block" alt=""/>
          </div>
          <div className="info">
              <p className="text-secondary fs-18 mb-0">{translate('branchMenuPage.dateAndTime')}</p>
              <p className="mb-0 fw-bold branch-name main_text_color icon_to_block d-flex align-items-center">
                  {currentDate}, {dayOfWeek}, {currentTime}
              </p>
          </div>
      </div>
  )
}

export default CurrentTime